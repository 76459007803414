/* .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    transition-delay: 150ms;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
} */

/* ---------------------------------- */

.ReactModal__Content {
    opacity: 1;
    transform: translateY(50rem);
    transition: transform 250ms ease-in-out;
}

.ReactModal__Content--after-open{
    opacity: 1;
    transform: translateY(0rem);
}

@media (min-width: 768px) {
    .ReactModal__Content--after-open{
        opacity: 1;
        transform: translateY(3rem);
    }
}



.ReactModal__Content--before-close{
    opacity: 1;
    transform: translateY(50rem);
    /* transition: transform 250ms ease-in-out; */
    /* transition: opacity 200ms ease-in-out; */
}
