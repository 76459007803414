/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  color: var(--tw-text-primary);
  background-color: white;
} */

.DateRangeDropdown {
  /* -moz-appearance:none; /* Firefox */
  /* -webkit-appearance:none; /* Safari and Chrome */
  /* appearance:none; */
  text-indent: 1px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.49502 8.50507C4.22166 8.23171 3.77844 8.23171 3.50507 8.50507C3.23171 8.77844 3.23171 9.22166 3.50507 9.49502L12 17.99L20.495 9.49502C20.7684 9.22166 20.7684 8.77844 20.495 8.50507C20.2217 8.23171 19.7784 8.23171 19.5051 8.50507L12 16.0101L4.49502 8.50507Z' fill='white' fill-opacity='0.98'/%3E%3C/svg%3E");
  background-position: right 1rem center
}

.Warning {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.1165 21C21.5442 21 22.4513 19.5245 21.7677 18.3143L13.6512 3.94643C12.9383 2.68453 11.0617 2.68452 10.3489 3.94643L2.23231 18.3143C1.54868 19.5245 2.45582 21 3.88346 21H20.1165ZM13 14.5H11L10.5 8H13.5L13 14.5ZM12 16C12.8547 16 13.5 16.7668 13.5 17.5C13.5 18.2332 12.8547 19 12 19C11.1105 19 10.5 18.2332 10.5 17.5C10.5 16.7668 11.1105 16 12 16Z' fill='%23E99720'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}

.Warning-Icon {
  display: inline-block;
  background-position: right 1rem center;
  width: 3rem;
  height: 1.5rem;
}

.Notification {
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='22' viewBox='0 0 19 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.49991 21.5466C10.6457 21.5466 11.5728 20.6741 11.5728 19.5958H7.42699C7.42699 20.6741 8.35408 21.5466 9.49991 21.5466ZM18.1353 16.9294L16.7916 15.6648V9.78304C16.7916 6.49904 14.3332 3.75421 11.0624 3.08761V1.94066C11.0624 1.12701 10.3645 0.470215 9.49991 0.470215C8.63533 0.470215 7.93741 1.12701 7.93741 1.94066V3.08761C4.66658 3.75421 2.20824 6.49904 2.20824 9.78304V15.6648L0.864494 16.9294C0.208245 17.547 0.666578 18.6057 1.59366 18.6057H17.3957C18.3332 18.6057 18.7916 17.547 18.1353 16.9294ZM10.5416 14.6845H8.45824V12.7239H10.5416V14.6845ZM10.5416 9.78304C10.5416 10.3222 10.0728 10.7633 9.49991 10.7633C8.92699 10.7633 8.45824 10.3222 8.45824 9.78304V7.82244C8.45824 7.28328 8.92699 6.84215 9.49991 6.84215C10.0728 6.84215 10.5416 7.28328 10.5416 7.82244V9.78304Z' fill='%23191919'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

#popup input[type='radio'], .App input[type='radio'] {
  padding: .55rem;
  border-width: 2px;
}

#popup input[type='radio']:disabled:not(:checked), .App input[type='radio']:disabled:not(:checked) {
  background-color: #f7f7f7;
  border-color: #DADEE0;
  /* background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23DADEE0' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e"); */
}

#popup input[type='radio']:checked, .App input[type='radio']:checked {
  color: #FFF;
  border-color: #0076b3;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%230076B3' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='4.5'/%3e%3c/svg%3e");
  /* color: #0076b3; */
  /* --tw-ring-color: #0076b3;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color); */
}

#popup input[type='radio']:checked:hover, .App input[type='radio']:checked:hover {
  border-color: #0076b3;
}