@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    text-size-adjust: 100%;
  }

  body {
    @apply min-h-screen;
    @apply bg-[#f6f6f6];
  }

  #__next {
    @apply min-h-screen;
  }

  strong {
    @apply font-semibold;
  }

  @media (hover: none) {
    html {
      touch-action: pan-x pan-y;
    }

    :not(textarea, input, [contenteditable='true']) {
      -webkit-touch-callout: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
    }

    button:focus {
      outline: none;
    }
  }

  [type='radio'] {
    @apply border-2 border-surface-icon-disabled bg-white bg-center bg-no-repeat;
    background-size: 100% 100%;
    /*background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23b9c3ca' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");*/
  }

  [type='radio']:disabled {
    @apply border-surface-icon-disabled bg-off-white;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23DADEE0' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='4.5'/%3e%3c/svg%3e");
  }

  .select-arrow-white {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  }
}

@layer components {
  .nav-bar-active-link {
    @apply bg-[#EDF3FF] text-utility-accent;
  }
  .nav-bar-active-link svg {
    @apply text-utility-accent;
  }

  .nav-bar-inactive {
    @apply text-gray-900;
  }
  .nav-bar-inactive svg {
    @apply text-surface-icon;
  }
}

@layer utilities {
  .-animation-delay-3000 {
    animation-delay: -3000ms;
  }
  .-animation-delay-6000 {
    animation-delay: -6000ms;
  }
}
